import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Heading, breakpoints, large, linkStyles, theme } from '@gassan-ui'
import { Brands } from '@pages/_stores/[...slug]'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import styled from 'styled-components'

const Link = styled.a`
  ${large}
  ${linkStyles};
  display: block;
  color: ${theme.colors.shades[800]};
  text-decoration: none;
  margin-bottom: 1rem;

  &:hover {
    text-decoration: underline;
  }
`

const Items = styled(Box)<{ itemCount: number }>`
  column-count: 1;
  column-gap: 2rem;
  width: 100%;

  @media screen and (min-width: ${breakpoints.small}) {
    column-count: ${(p) => (p.itemCount > 6 ? 2 : 1)};
    max-width: ${(p) => (p.itemCount > 6 ? '40rem' : '20rem')};
  }

  @media screen and (min-width: ${breakpoints.large}) {
    column-count: ${(p) =>
      p.itemCount < 6 ? 1 : p.itemCount < 12 ? 2 : p.itemCount < 18 ? 3 : p.itemCount < 24 && 4};
    max-width: ${(p) =>
      p.itemCount < 6
        ? '20rem'
        : p.itemCount < 12
          ? '40rem'
          : p.itemCount < 18
            ? '60rem'
            : p.itemCount < 24 && '80rem'};
  }

  @media screen and (min-width: ${breakpoints.xlarge}) {
    column-count: ${(p) =>
      p.itemCount < 6 ? 1 : p.itemCount < 12 ? 2 : p.itemCount < 18 ? 3 : p.itemCount < 24 ? 4 : 5};
    max-width: ${(p) =>
      p.itemCount < 6
        ? '20rem'
        : p.itemCount < 12
          ? '40rem'
          : p.itemCount < 18
            ? '60rem'
            : p.itemCount < 24
              ? '80rem'
              : '100rem'};
  }
`

type Props = {
  items: Brands[]
}

const sortAlphabetically = (items: Brands[]) => {
  return items.sort(function (a, b) {
    const titleA = a.title.toLowerCase()
    const titleB = b.title.toLowerCase()
    return titleA < titleB ? -1 : titleA > titleB ? 1 : 0
  })
}

const AllBrands: FC<Props> = ({ items }) => {
  const { t } = useTranslation('other')
  const sortedItems = sortAlphabetically(items)

  return (
    <Box
      bg="pampasLight"
      width={{ _: 'calc(100% - 2rem)', medium: 'calc(100% - 4rem)', xlarge: 'calc(100% - 5.5rem)' }}
      maxWidth="109rem"
      p={{ _: '2rem 1.5rem', medium: '3.5rem', xlarge: '5rem' }}
      m="0 auto"
    >
      <Heading variant="h3" as="h3">
        {t('allBrands')}
      </Heading>
      <Items itemCount={items.length}>
        {sortedItems.map((brand, index) => (
          <LocalizedLink href={`/${brand.url_slug}`} key={index}>
            <Link>{brand.title}</Link>
          </LocalizedLink>
        ))}
      </Items>
    </Box>
  )
}

export default AllBrands
